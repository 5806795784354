// NPM
import * as React from "react"
import { graphql } from 'gatsby'
import _ from 'lodash'

// Components
import Layout from "src/layouts/en"
import Updates from "src/components/pages/Updates/Updates"

const UpdatesEn = ({ location, data }) => {
  const topNav = data.topNav
  const sections = _.get(data, "updatesPage.sections", [])
  const posts = _.get(data, "posts.nodes", [])
  const tweets = _.get(data, "tweets.nodes", [])
  const bottomNav = data.bottomNav
  const socialMedia = _.get(data, `socialMedia.nodes`, [])

  return (
    <Layout 
      location={ location }
      topNav={ topNav }
      page={ data.updatesPage }
      bottomNav={ bottomNav }
      socialMedia={ socialMedia }>
        <Updates 
          sections={ sections }
          posts={ posts }
          tweets={ tweets } 
        />
    </Layout>
  )
}

export default UpdatesEn

export const query = graphql`
  query UpdatesEnQuery($id: String) {
    topNav: strapiTopNav(locale: {eq: "en"}) {
      ...topNavContent
    }

    updatesPage: strapiPage(id: {eq: $id}) {
      ...pageInformation
    }

    posts: allStrapiPost(filter: {locale: {eq: "en"}}) {
      nodes {
        ...post
      }
    }

    tweets: allStrapiTweet(filter: {locale: {eq: "en"}}) {
      nodes {
        ...tweet
      }
    }

    bottomNav: strapiBottomNav(locale: {eq: "en"}) {
      ...bottomNavContent
    }

    socialMedia: allStrapiSocialMedia(filter: {locale: {eq: "en"}}) {
      nodes {
        ...socialMedia
      }
    }
  }
`